<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nprogress .bar {
  background: #fff !important;
}
#nprogress .spinner-icon {
  border-top-color: #fff;
  border-left-color: #fff;
}
.k-pager-wrap .k-state-selected:hover {
    color: #fff;
    background-color: #438eb9 !important;
    cursor: default;
}
.k-pager-wrap .k-state-selected {
    color: #fff;
    background-color: #438eb9 !important;
}
.k-pager-numbers .k-link, .k-pager-numbers .k-link:link {
    color: #438eb9 !important;
}

.swal.fire2-container {
  z-index: 9999;
}
</style>
