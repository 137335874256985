import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/redirect',
      component: () => import('@/layouts/Default'),
      hidden: true,
      children: [{
        path: '/redirect/:path*',
        component: () => import('@/pages/redirect/index')
      }]
    }, {
      path: '/',
      component: () => import('@/layouts/Default'),
      children: [{
        path: '',
        component: () => import('@/pages/Home/Home'),
        name: 'Home',
        meta: {
          pai: 'Home',
          title: 'VoX - Home',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: true,
            url: ''
          }],
          noCache: true
        }
      },
      {
        path: 'sistema/clientes',
        component: () => import('@/pages/Sistema/Clientes'),
        name: 'Clientes',
        meta: {
          path: '/sistema/clientes',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: true,
            url: false
          }],
          noCache: true
        }
      },
      {
        path: 'sistema/clientes/novo',
        component: () => import('@/pages/Sistema/ClientesForm'),
        name: 'ClientesNovo',
        meta: {
          path: '/sistema/clientes',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: false,
            url: '/sistema/clientes'
          }, {
            pagina: 'Novo',
            class: '',
            active: true,
            url: false
          }],
          noCache: true
        }
      },
      {
        path: 'sistema/clientes/:id',
        component: () => import('@/pages/Sistema/ClientesForm'),
        name: 'ClientesEditar',
        meta: {
          path: '/sistema/clientes',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: false,
            url: '/sistema/clientes'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }],
          noCache: true
        }
      },
      {
        path: 'sistema/clientes/:id/agenda/:idAgenda',
        component: () => import('@/pages/Sistema/ClientesAgenda'),
        name: 'ClientesEditarAgendaId',
        meta: {
          path: '/sistema/agenda',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: false,
            url: '/sistema/clientes'
          },
          {
            pagina: 'Agenda',
            class: '',
            active: true,
            url: '/sistema/agenda'
          }
          ],
          noCache: true
        }
      },
      {
        path: 'sistema/clientes/:id/agenda',
        component: () => import('@/pages/Sistema/ClientesAgenda'),
        name: 'ClientesEditarAgenda',
        meta: {
          path: '/sistema/agenda',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: false,
            url: '/sistema/clientes'
          },
          {
            pagina: 'Agenda',
            class: '',
            active: true,
            url: '/sistema/agenda'
          }
          ],
          noCache: true
        }
      },
      {
        path: 'sistema/clientes/:id/vendas',
        component: () => import('@/pages/Sistema/ClientesVendas'),
        name: 'ClientesEditarVendas',
        meta: {
          path: '/sistema/vendas',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: false,
            url: '/sistema/clientes'
          },
          {
            pagina: 'Venda',
            class: '',
            active: true,
            url: '/sistema/vendas'
          }
          ],
          noCache: true
        }
      },

      {
        path: 'sistema/agenda',
        component: () => import('@/pages/Sistema/Agenda'),
        name: 'Agenda',
        meta: {
          path: '/sistema/agenda',
          pai: 'Sistema',
          title: 'VoX - Agenda',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Agenda',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      },
      {
        path: 'sistema/vendas',
        component: () => import('@/pages/Sistema/Vendas'),
        name: 'Vendas',
        meta: {
          path: '/sistema/vendas',
          pai: 'Sistema',
          title: 'VoX - Vendas',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Vendas',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      },
      {
        path: 'sistema/vendas/:idVenda/:id',
        component: () => import('@/pages/Sistema/ClientesVendas'),
        name: 'EditarVendas',
        meta: {
          path: '/sistema/vendas',
          pai: 'Sistema',
          title: 'VoX - Clientes',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Vendas',
            class: '',
            active: false,
            url: '/sistema/vendas'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      },
      {
        path: 'inicio/atualizacoes',
        component: () => import('@/pages/Inicio/Atualizacoes'),
        name: 'Atualizacoes',
        meta: {
          path: '/inicio/atualizacoes',
          pai: 'Início',
          title: 'VoX - Atualizações',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Atualizações',
            class: '',
            active: true,
            url: false
          }],
          noCache: true
        }
      },
      {
        path: 'inicio/atualizacoes/novo',
        component: () => import('@/pages/Inicio/AtualizacoesForm'),
        name: 'AtualizacoesFormNovo',
        meta: {
          path: '/inicio/atualizacoes',
          pai: 'Início',
          title: 'VoX - Atualizações',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Atualizações',
            class: '',
            active: false,
            url: '/inicio/atualizacoes'
          },
          {
            pagina: 'Novo',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      },
      {
        path: 'inicio/atualizacoes/:id',
        component: () => import('@/pages/Inicio/AtualizacoesForm'),
        name: 'AtualizacoesFormId',
        meta: {
          path: '/inicio/atualizacoes',
          pai: 'Início',
          title: 'VoX - Atualizações',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Atualizações',
            class: '',
            active: false,
            url: '/inicio/atualizacoes'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      },
      {
        path: 'inicio/chamados',
        component: () => import('@/pages/Inicio/Chamados'),
        name: 'Chamados',
        meta: {
          path: '/inicio/chamados',
          pai: 'Início',
          title: 'VoX - Chamados',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Chamados',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      }, {
        path: 'inicio/chamados/novo',
        component: () => import('@/pages/Inicio/ChamadosForm'),
        name: 'ChamadosFormNovo',
        meta: {
          path: '/inicio/chamados',
          pai: 'Início',
          title: 'VoX - Chamados',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Chamados',
            class: '',
            active: false,
            url: '/inicio/chamados'
          },
          {
            pagina: 'Novo',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      }, {
        path: 'inicio/chamados/:id',
        component: () => import('@/pages/Inicio/ChamadosForm'),
        name: 'ChamadosFormId',
        meta: {
          path: '/inicio/chamados',
          pai: 'Início',
          title: 'VoX - Chamados',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Chamados',
            class: '',
            active: false,
            url: '/inicio/chamados'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }
          ],
          noCache: true
        }
      },
      {
        path: 'inicio/minha-conta',
        component: () => import('@/pages/Inicio/MinhaConta'),
        name: 'MinhaConta',
        meta: {
          path: '/inicio/minha-conta',
          pai: 'Início',
          title: 'VoX - Minha Conta',
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Minha Conta',
            class: '',
            active: true,
            url: false
          }],
          noCache: true
        }
      },
      {
        path: 'admin/usuarios',
        component: () => import('@/pages/Admin/Usuarios'),
        name: 'Usuarios',
        meta: {
          path: '/admin/usuarios',
          pai: 'Admin',
          title: 'VoX - Usuários',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Usuários',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/usuarios/novo',
        component: () => import('@/pages/Admin/UsuariosForm'),
        name: 'UsuariosFormNovo',
        meta: {
          path: '/admin/usuarios',
          pai: 'Admin',
          title: 'VoX - Usuários',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Usuários',
            class: '',
            active: false,
            url: '/admin/usuarios'
          },
          {
            pagina: 'Novo',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/usuarios/:id',
        component: () => import('@/pages/Admin/UsuariosForm'),
        name: 'UsuariosFormId',
        meta: {
          path: '/admin/usuarios',
          pai: 'Admin',
          title: 'VoX - Usuários',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Usuários',
            class: '',
            active: false,
            url: '/admin/usuarios'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/grupo-de-acesso',
        component: () => import('@/pages/Admin/GrupoDeAcesso'),
        name: 'GrupoDeAcesso',
        meta: {
          path: '/admin/grupo-de-acesso',
          pai: 'Admin',
          title: 'VoX - Grupo de Acesso',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Grupo de Acesso',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/grupo-de-acesso/novo',
        component: () => import('@/pages/Admin/GrupoDeAcessoForm'),
        name: 'GrupoDeAcessoFormNovo',
        meta: {
          path: '/admin/grupo-de-acesso',
          pai: 'Admin',
          title: 'VoX - Grupo de Acesso',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Grupo de Acesso',
            class: '',
            active: false,
            url: '/admin/grupo-de-acesso'
          },
          {
            pagina: 'Novo',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/grupo-de-acesso/:id',
        component: () => import('@/pages/Admin/GrupoDeAcessoForm'),
        name: 'GrupoDeAcessoFormId',
        meta: {
          path: '/admin/grupo-de-acesso',
          pai: 'Admin',
          title: 'VoX - Grupo de Acesso',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Grupo de Acesso',
            class: '',
            active: false,
            url: '/admin/grupo-de-acesso'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/mailling',
        component: () => import('@/pages/Admin/Mailling'),
        name: 'Mailling',
        meta: {
          path: '/admin/mailling',
          pai: 'Admin',
          title: 'VoX - Mailling',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Mailling',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/mailling/novo',
        component: () => import('@/pages/Admin/MaillingForm'),
        name: 'MaillingFormNovo',
        meta: {
          path: '/admin/mailling',
          pai: 'Admin',
          title: 'VoX - Mailling',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Mailling',
              class: '',
              active: false,
              url: '/admin/mailling'
            },
            {
              pagina: 'Novo',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/mailling/:id',
        component: () => import('@/pages/Admin/MaillingForm'),
        name: 'MaillingFormId',
        meta: {
          path: '/admin/mailling',
          pai: 'Admin',
          title: 'VoX - Mailling',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Mailling',
              class: '',
              active: false,
              url: '/admin/mailling'
            },
            {
              pagina: 'Editar',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      },
      {
        path: 'admin/especie',
        component: () => import('@/pages/Admin/Especie'),
        name: 'Especie',
        meta: {
          path: '/admin/especie',
          pai: 'Admin',
          title: 'VoX - Especie',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Especie',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/especie/novo',
        component: () => import('@/pages/Admin/EspecieForm'),
        name: 'EspecieFormNovo',
        meta: {
          path: '/admin/especie',
          pai: 'Admin',
          title: 'VoX - Especie',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Especie',
              class: '',
              active: false,
              url: '/admin/especie'
            },
            {
              pagina: 'Novo',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/especie/:id',
        component: () => import('@/pages/Admin/EspecieForm'),
        name: 'EspecieFormId',
        meta: {
          path: '/admin/especie',
          pai: 'Admin',
          title: 'VoX - Especie',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Especie',
              class: '',
              active: false,
              url: '/admin/especie'
            },
            {
              pagina: 'Editar',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      },
      {
        path: 'admin/tipo-de-emprestimo',
        component: () => import('@/pages/Admin/TipoDeEmprestimo'),
        name: 'TipoDeEmprestimo',
        meta: {
          path: '/admin/tipo-de-emprestimo',
          pai: 'Admin',
          title: 'VoX - Tipo de Emprestimo',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Tipo de Emprestimo',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/tipo-de-emprestimo/novo',
        component: () => import('@/pages/Admin/TipoDeEmprestimoForm'),
        name: 'TipoDeEmprestimoFormNovo',
        meta: {
          path: '/admin/tipo-de-emprestimo',
          pai: 'Admin',
          title: 'VoX - Tipo de Emprestimo',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Tipo de Emprestimo',
              class: '',
              active: false,
              url: '/admin/tipo-de-emprestimo'
            },
            {
              pagina: 'Novo',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/tipo-de-emprestimo/:id',
        component: () => import('@/pages/Admin/TipoDeEmprestimoForm'),
        name: 'TipoDeEmprestimoFormId',
        meta: {
          path: '/admin/tipo-de-emprestimo',
          pai: 'Admin',
          title: 'VoX - Tipo de Emprestimo',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Tipo de Emprestimo',
              class: '',
              active: false,
              url: '/admin/tipo-de-emprestimo'
            },
            {
              pagina: 'Editar',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      },
      {
        path: 'admin/orgao',
        component: () => import('@/pages/Admin/Orgao'),
        name: 'Orgao',
        meta: {
          path: '/admin/orgao',
          pai: 'Admin',
          title: 'VoX - Orgão',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Orgão',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/orgao/novo',
        component: () => import('@/pages/Admin/OrgaoForm'),
        name: 'OrgaoFormNovo',
        meta: {
          path: '/admin/orgao',
          pai: 'Admin',
          title: 'VoX - Orgão',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Orgão',
              class: '',
              active: false,
              url: '/admin/orgao'
            },
            {
              pagina: 'Novo',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      }, {
        path: 'admin/orgao/:id',
        component: () => import('@/pages/Admin/OrgaoForm'),
        name: 'OrgaoFormId',
        meta: {
          path: '/admin/orgao',
          pai: 'Admin',
          title: 'VoX - Orgão',
          noCache: true,
          navigation: [{
              pagina: 'Home',
              class: 'ace-icon fa fa-home home-icon',
              active: false,
              url: '/'
            },
            {
              pagina: 'Orgão',
              class: '',
              active: false,
              url: '/admin/orgao'
            },
            {
              pagina: 'Editar',
              class: '',
              active: true,
              url: false
            }
          ]
        }
      },
      {
        path: 'admin/status',
        component: () => import('@/pages/Admin/Status'),
        name: 'Status',
        meta: {
          path: '/admin/status',
          pai: 'Admin',
          title: 'VoX - Status',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Status',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/status/novo',
        component: () => import('@/pages/Admin/StatusForm'),
        name: 'StatusFormNovo',
        meta: {
          path: '/admin/status',
          pai: 'Admin',
          title: 'VoX - Status',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Status',
            class: '',
            active: false,
            url: '/admin/status'
          },
          {
            pagina: 'Novo',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'admin/status/:id',
        component: () => import('@/pages/Admin/StatusForm'),
        name: 'StatusFormId',
        meta: {
          path: '/admin/status',
          pai: 'Admin',
          title: 'VoX - Status',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Status',
            class: '',
            active: false,
            url: '/admin/status'
          },
          {
            pagina: 'Editar',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'relatorio/data',
        component: () => import('@/pages/Relatorio/Data'),
        name: 'RelatorioData',
        meta: {
          path: '/relatorio/data',
          pai: 'Admin',
          title: 'VoX - Relatório Data',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Relatório Data',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      },
      {
        path: 'logs/logs',
        component: () => import('@/pages/Logs/Logs'),
        name: 'Logs',
        meta: {
          path: '/logs/logs',
          pai: 'Logs',
          title: 'VoX - Logs',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Logs',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      }
      ]
    },
    {
      path: '/acessar',
      component: () => import('@/layouts/Login'),
      children: [{
        path: '',
        component: () => import('@/pages/Login/Acessar'),
        name: 'Acessar',
        meta: {
          title: 'VoX - Acessar',
          noCache: true
        }
      }]
    },
    {
      path: '/imprimir',
      component: () => import('@/layouts/Imprimir'),
      children: [{
        path: 'recibo/:id',
        component: () => import('@/pages/Imprimir/Recibo'),
        name: 'Recibo',
        meta: {
          title: 'VoX - Acessar',
          noCache: true
        }
      }, {
        path: 'contrato/:id',
        component: () => import('@/pages/Imprimir/Contrato'),
        name: 'Contrato',
        meta: {
          title: 'VoX - Acessar',
          noCache: true
        }
      }]
    },
    {
      path: '/sistema',
      component: () => import('@/layouts/Imprimir'),
      children: [
    {
      path: 'vendas/:idVenda/:id/visualizar',
      component: () => import('@/pages/Sistema/ClientesVendasVisualizar'),
      name: 'ClientesVendasVisualizar',
      meta: {
        path: '/sistema/vendas',
        pai: 'Sistema',
        title: 'VoX - Clientes',
        navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Clientes',
            class: '',
            active: false,
            url: '/sistema/clientes'
          },
          {
            pagina: 'Venda',
            class: '',
            active: true,
            url: '/sistema/vendas'
          }
        ],
        noCache: true
      }
    }]},
    {
      path: '*',
      component: () => import('@/layouts/Default'),
      children: [{
        path: '',
        component: () => import('@/pages/Erro/404'),
        name: '404',
        meta: {
          pai: 'Admin',
          title: 'VoX - Erro',
          noCache: true,
          navigation: [{
            pagina: 'Home',
            class: 'ace-icon fa fa-home home-icon',
            active: false,
            url: '/'
          },
          {
            pagina: 'Erro 404',
            class: '',
            active: true,
            url: false
          }
          ]
        }
      }]
    }
  ]
})
