export const setMenu = (state, payload) => {
  state.menu = payload
}

export const setUser = (state, payload) => {
  state.user = payload
}

export const setLogsTotal = (state, payload) => {
  state.totalLogs = payload
}

export const setLogs = (state, payload) => {
  state.Logs = payload
}

export const setTotalVencidos = (state, payload) => {
  state.totalVencidos = payload
}